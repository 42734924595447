import { Api } from '../api'

export const GET_LIST_MEMBERSHIP = "GET_LIST_MEMBERSHIP";
export const GET_TOTAL_EFT_MTD = "GET_TOTAL_EFT_MTD";
export const GET_TOTAL_EFT_TODAY = "GET_TOTAL_EFT_TODAY";
export const GET_TOTAL_PIF_MTD = "GET_TOTAL_PIF_MTD";
export const GET_TOTAL_PIF_TODAY = "GET_TOTAL_PIF_TODAY";
export const GET_COMPARE_PIF_TODAY = "GET_COMPARE_PIF_TODAY";
export const GET_COMPARE_EFT_TODAY = "GET_COMPARE_EFT_TODAY";
export const GET_COMPARE_PIF_MTD = "GET_COMPARE_PIF_MTD";
export const GET_COMPARE_EFT_MTD = "GET_COMPARE_EFT_MTD";
export const GET_COMPARE_PIF_FIRST_MONTH = "GET_COMPARE_PIF_FIRST_MONTH";
export const GET_COMPARE_EFT_FIRST_MONTH = "GET_COMPARE_EFT_FIRST_MONTH";
export const GET_COMPARE_PIF_SECOND_MONTH = "GET_COMPARE_PIF_SECOND_MONTH";
export const GET_COMPARE_EFT_SECOND_MONTH = "GET_COMPARE_EFT_SECOND_MONTH";

export const getListMembership = ({
  page, orgId = "", clubId = "", paymentId = "", categoryId = "", shiftId = "", size, search = "", isMobile = "", isFreeTrial = ""
}) => {
  return async (dispatch) => {
    try {
      if (search === null) search = "";
      const resp = await Api.get(
        `package/membership?page=${page}&orgId=${orgId}&clubId=${clubId}&paymentId=${paymentId}&categoryId=${categoryId}&shiftId=${shiftId}&size=${size}&search=${search}&isMobile=${isMobile}&isFreeTrial=${isFreeTrial}`
      )
      let data = resp.data.data ? resp.data.data : []

      dispatch({
        type: GET_LIST_MEMBERSHIP,
        data,
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
};

export const getTotalUnit = ({
    orgId = '', clubId = '', paymentName = '', startDate = '', endDate = '', 
    type = '', compareDay = false, compareMtd = false, compare1 = false, compare2 = false
  }) => {
  return async (dispatch) => {
    try {
      if (orgId === '' || clubId === '') {
        return;
      }
      
      const resp = await Api.get(`report/count-unit?orgId=${orgId}&clubId=${clubId}&paymentName=${paymentName}&startDate=${startDate}&endDate=${endDate}`)

      let data = resp.data.data ? resp.data.data : []
      let dispatchType = ''

      if (type === "today" && paymentName === "pif") {
        if (compareDay) {
          dispatchType = GET_COMPARE_PIF_TODAY
        } else {
          dispatchType = GET_TOTAL_PIF_TODAY
        }
      } else if (type === "today" && paymentName === "eft") {
        if (compareDay) {
          dispatchType = GET_COMPARE_EFT_TODAY
        } else {
          dispatchType = GET_TOTAL_EFT_TODAY
        }
      } else if (type === "mtd" && paymentName === "pif") {
        if (compare1) {
          dispatchType = GET_COMPARE_PIF_FIRST_MONTH;
        } else if (compare2) {
          dispatchType = GET_COMPARE_PIF_SECOND_MONTH;
        } else if (compareMtd) {
          dispatchType = GET_COMPARE_PIF_MTD;
        } else {
          dispatchType = GET_TOTAL_PIF_MTD
        }
      } else if (type === "mtd" && paymentName === "eft") {
        if (compare1) {
          dispatchType = GET_COMPARE_EFT_FIRST_MONTH;
        } else if (compare2) {
          dispatchType = GET_COMPARE_EFT_SECOND_MONTH;
        } else if (compareMtd) {
          dispatchType = GET_COMPARE_EFT_MTD;
        } else {
          dispatchType = GET_TOTAL_EFT_MTD;
        }
      }

      dispatch({
        type: dispatchType,
        data
      })
      // return data
    } catch (err) {
      console.log(err)
      throw err?.response?.data
    }
  }
}

// export const deleteNotification = id => dispatch => {
//   axios.post("/api/notification/delete", { id }).then(res => {
//     dispatch({
//       type: DELETE_NOTIFICATION,
//       payload: res.data
//     });
//   });
// };

// export const deleteAllNotification = () => dispatch => {
//   axios.post("/api/notification/delete-all").then(res => {
//     dispatch({
//       type: DELETE_ALL_NOTIFICATION,
//       payload: res.data
//     });
//   });
// };

// export const createNotification = notification => dispatch => {
//   axios.post("/api/notification/add", { notification }).then(res => {
//     dispatch({
//       type: CREATE_NOTIFICATION,
//       payload: res.data
//     });
//   });
// };
