import {
    GET_MEMBER_CHECK, GET_MEMBER_CHECK_PT, GET_MEMBER_CHECK_CLASS,
    GET_REVENUE_MARCOMM_MTD, GET_REVENUE_MARCOMM_COMPARE,
    GET_REVENUE_OPERATION_MTD, GET_REVENUE_OPERATION_COMPARE,

    GET_SALES_PERFORMANCE,
    GET_FITNESS_PERFORMANCE,
    GET_OPERATION_PERFORMANCE,
    GET_TOTAL_REVENUE,
} from "../actions/DashboardActions";

const initialState = {
    memberCheck: {},
    memberCheckPt: {},
    memberCheckClass: {},

    revenueMarcomm: {
        mtd: "",
        compareMtd: ""
    },
    revenueOperation: {
        mtd: "",
        compareMtd: ""
    },

    salesPerformance: {},
    fitnessPerformance: {},
    operationPerformance: {},
    totalRevenueClub: {}
};

const DashboardReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_MEMBER_CHECK: {
            return {
                ...state,
                memberCheck: action.data
            };
        }

        case GET_MEMBER_CHECK_PT: {
            return {
                ...state,
                memberCheckPt: action.data
            };
        }

        case GET_MEMBER_CHECK_CLASS: {
            return {
                ...state,
                memberCheckClass: action.data
            };
        }

        case GET_REVENUE_MARCOMM_MTD: {
            return {
                ...state,
                revenueMarcomm: {
                    ...state.revenueMarcomm,
                    mtd: action.data[0]
                }
            }
        }

        case GET_REVENUE_MARCOMM_COMPARE: {
            return {
                ...state,
                revenueMarcomm: {
                    ...state.revenueMarcomm,
                    compareMtd: action.data[0]
                }
            }
        }

        case GET_REVENUE_OPERATION_MTD: {
            return {
                ...state,
                revenueOperation: {
                    ...state.revenueOperation,
                    mtd: action.data[0]
                }
            }
        }

        case GET_REVENUE_OPERATION_COMPARE: {
            return {
                ...state,
                revenueOperation: {
                    ...state.revenueOperation,
                    compareMtd: action.data[0]
                }
            }
        }

        case GET_SALES_PERFORMANCE: {
            return {
                ...state,
                salesPerformance: action.data
            }
        }

        case GET_FITNESS_PERFORMANCE: {
            return {
                ...state,
                fitnessPerformance: action.data
            }
        }

        case GET_OPERATION_PERFORMANCE: {
            return {
                ...state,
                operationPerformance: action.data
            }
        }

        case GET_TOTAL_REVENUE: {
            return {
                ...state,
                totalRevenueClub: action.data
            }
        }

        default: {
            return {
                ...state,
            };
        }
    }
};

export default DashboardReducer;
