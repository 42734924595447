import { Api } from '../api'

export const GET_LIST_MEMBER = "GET_LIST_MEMBER";
export const GET_ONLY_MEMBER = "GET_ONLY_MEMBER";
export const GET_DETAIL_MEMBER = "GET_DETAIL_MEMBER";
export const GET_LIST_CUTI = "GET_LIST_CUTI";
export const GET_DETAIL_CUTI = "GET_DETAIL_CUTI";
export const GET_REPORT_MEMBER_CHECKIN = "GET_REPORT_MEMBER_CHECKIN";
export const GET_MEMBER_EFT_INVOICE = "GET_MEMBER_EFT_INVOICE";

export const GET_MEMBER_CHECKIN_TODAY_PEAK_HOURS = 'GET_MEMBER_CHECKIN_TODAY_PEAK_HOURS';
export const GET_MEMBER_CHECKIN_MTD_PEAK_HOURS = 'GET_MEMBER_CHECKIN_MTD_PEAK_HOURS';
export const GET_MEMBER_CHECKIN_TODAY_OFF_PEAK_HOURS = 'GET_MEMBER_CHECKIN_TODAY_OFF_PEAK_HOURS';
export const GET_MEMBER_CHECKIN_MTD_OFF_PEAK_HOURS = 'GET_MEMBER_CHECKIN_MTD_OFF_PEAK_HOURS'

export const GET_COMPARE_MEMBER_CHECKIN_TODAY_PEAK = 'GET_COMPARE_MEMBER_CHECKIN_TODAY_PEAK';
export const GET_COMPARE_MEMBER_CHECKIN_TODAY_OFF_PEAK = 'GET_COMPARE_MEMBER_CHECKIN_TODAY_OFF_PEAK';

export const GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_PEAK = 'GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_PEAK';
export const GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_OFF_PEAK = 'GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_OFF_PEAK';
export const GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_PEAK = 'GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_PEAK';
export const GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_OFF_PEAK = 'GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_OFF_PEAK';

export const GET_MEMBER_PIF_EXPIRED = 'GET_MEMBER_PIF_EXPIRED';
export const GET_MEMBER_FREE_TRIAL = 'GET_MEMBER_FREE_TRIAL';
export const GET_MEMBER_CHECKIN_CHECKOUT = 'GET_MEMBER_CHECKIN_CHECKOUT';
export const GET_MEMBER_ACTIVE = 'GET_MEMBER_ACTIVE';
export const GET_MEMBER_REFFERAL = 'GET_MEMBER_REFFERAL';
export const GET_MEMBER_NOTES = 'GET_MEMBER_NOTES';

export const GET_GENDER_MEMBER = 'GET_GENDER_MEMBER';
export const GET_AGE_MEMBER = 'GET_AGE_MEMBER';
export const GET_MEMBER_99K = 'GET_MEMBER_99K';

export const CLEAR_LIST_MEMBER = 'CLEAR_LIST_MEMBER';

export const getListMember = ({ page = '', size = '', orgId = '', clubId = '', search = '', paymentId = '', confirmTnc = '', cardStatus = '', isExport = false }) => {
    return async (dispatch) => {
        try {
            if (search === null) {
                search = "";
            }
            const resp = await Api.get(`member?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}&paymentId=${paymentId}&confirmTnc=${confirmTnc}&cardStatus=${cardStatus}`)
            let data = resp.data.data ? resp.data.data : []

            if (isExport) {
                return data;
            }

            dispatch({
                type: GET_LIST_MEMBER,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
};

// get only list member
export const getMember = ({ page = '', size = '', orgId = '', clubId = '', search = '' }) => {
    return async (dispatch) => {
        try {
            if (search === null) {
                search = "";
            }
            const resp = await Api.get(`member-only?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_ONLY_MEMBER,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const getDetailMember = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`member/${id}`);
            let data = resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_DETAIL_MEMBER,
                data,
            });
            // return data
        } catch (err) {
            console.log(err);
            throw err?.response?.data;
        }
    };
};

export const getMembersLeads = (page, size, orgId, clubId, search) => {
    return async (dispatch) => {
        try {
            if (search === null) search = "";
            const resp = await Api.get(`membersAndLeads?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_LIST_MEMBER,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const getListCuti = ({ page = '', size = '', orgId = '', clubId = '', search = '' }) => {
    return async (dispatch) => {
        try {
            if (search == null) search = "";
            const resp = await Api.get(`member-cuti?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`)
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_LIST_CUTI,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
}

export const getDetailCuti = (id) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`member-cuti/${id}`);
            let data = resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_DETAIL_CUTI,
                data,
            })
        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const getMemberCheckin = ({ page = '', size = '', orgId = '', clubId = '', checkin = '', checkout = '', search = '', memberId = '', isExport = false }) => {
    return async (dispatch) => {
        try {
            if (search === null) search = ''
            const resp = await Api.get(
                `report/member-checkin?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&checkin=${checkin}&checkout=${checkout}&search=${search}&memberId=${memberId}`
            )

            let data = resp.data.data ? resp.data.data : [];
            if (isExport) {
                return data;
            }

            dispatch({
                type: GET_REPORT_MEMBER_CHECKIN,
                data,
            })

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const memberEftInvoice = ({ page = '', size = '', orgId = '', clubId = '', memberId = '' }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `report/member-eft-invoice?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&memberId=${memberId}`
            )

            let data = resp.data.data ? resp.data.data : [];

            dispatch({
                type: GET_MEMBER_EFT_INVOICE,
                data,
            })

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const getCountMemberCheckin = ({
    orgId = '', clubId = '', checkin = '', checkout = '', type = '', shiftType = '',
    isReturn = false, compareDay = false, compare1 = false, compare2 = false
}) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `report/count-member-checkin?orgId=${orgId}&clubId=${clubId}&checkin=${checkin}&checkout=${checkout}&shiftType=${shiftType}`
            )

            let data = resp.data ? resp.data : null;
            let dispatchType = '';

            if (type === "today" && shiftType === "off peak hours") {
                if (compareDay) {
                    dispatchType = GET_COMPARE_MEMBER_CHECKIN_TODAY_OFF_PEAK;
                } else {
                    dispatchType = GET_MEMBER_CHECKIN_TODAY_OFF_PEAK_HOURS;
                }
            } else if (type === "today" && shiftType === "peak hours") {
                if (compareDay) {
                    dispatchType = GET_COMPARE_MEMBER_CHECKIN_TODAY_PEAK;
                } else {
                    dispatchType = GET_MEMBER_CHECKIN_TODAY_PEAK_HOURS;
                }
            } else if (type === "mtd" && shiftType === "off peak hours") {
                if (compare1) {
                    dispatchType = GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_OFF_PEAK;
                } else if (compare2) {
                    dispatchType = GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_OFF_PEAK;
                } else {
                    dispatchType = GET_MEMBER_CHECKIN_MTD_OFF_PEAK_HOURS;
                }
            } else if (type === "mtd" && shiftType === "peak hours") {
                if (compare1) {
                    dispatchType = GET_COMPARE_MEMBER_CHECKIN_FIRST_MTD_PEAK;
                } else if (compare2) {
                    dispatchType = GET_COMPARE_MEMBER_CHECKIN_SECOND_MTD_PEAK;
                } else {
                    dispatchType = GET_MEMBER_CHECKIN_MTD_PEAK_HOURS;
                }
            }

            if (isReturn) {
                return data
            } else {
                dispatch({
                    type: dispatchType,
                    data,
                })
            }

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const getMemberPIFExpired = ({ page = "", size = "", orgId = "", clubId = "", startDate = "", endDate = "", search = "", isExport = false }) => {
    return async (dispatch) => {
        try {
            if (search === null) {
                search = ""
            }
            const resp = await Api.get(`report/pif-expired?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&search=${search}`)

            let data = resp.data.data ? resp.data.data : null;

            if (isExport) {
                return data;
            }
            dispatch({
                type: GET_MEMBER_PIF_EXPIRED,
                data
            })
        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const getSummaryCheckin = (orgId = '', clubId = '', startDate = '', endDate = '') => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `report/summary-member-checkin?orgId=${orgId}&clubId=${clubId}&startDate=${startDate}&endDate=${endDate}`
            )

            let data = resp.data.data ? resp.data.data : null;

            return data
        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const getMemberFreeTrial = ({ page = '', size = '', orgId = '', clubId = '', search = '', isExport = false }) => {
    return async (dispatch) => {
        try {
            if (search === null) search = "";
            const resp = await Api.get(
                `member-freetrial?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`
            )

            let data = resp.data.data ? resp.data.data : null;

            if (isExport) {
                return data;
            }

            dispatch({
                type: GET_MEMBER_FREE_TRIAL,
                data
            })

        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const getMemberCheckinCheckout = ({ page = '', size = '', orgId = '', clubId = '', status = '', search = '', startDate = '', endDate = '' }) => {
    return async (dispatch) => {
        try {
            if (search === null) search = "";
            const resp = await Api.get(
                `member-checkin-checkout?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&status=${status}&search=${search}&startDate=${startDate}&endDate=${endDate}`)

            let data = resp.data.data ? resp.data.data : null;

            dispatch({
                type: GET_MEMBER_CHECKIN_CHECKOUT,
                data
            })

        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const getMemberActive = ({ page = '', size = '', orgId = '', clubId = '', paymentId = '', search = '', withPt = false, sessionPt = '', isExport = false }) => {
    return async (dispatch) => {
        try {
            if (search == null) search = "";
            if (sessionPt == null) sessionPt = "";

            const resp = await Api.get(
                `member-active?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&paymentId=${paymentId}&search=${search}&withPt=${withPt}&sessionPt=${sessionPt}`
            )

            let data = resp.data.data ? resp.data.data : null;

            if (isExport) {
                return data
            }

            dispatch({
                type: GET_MEMBER_ACTIVE,
                data
            })

        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const memberRefferal = ({ page = '', size = '', orgId = '', clubId = '', search = '', isExport = false }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `report/member-refferal?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&search=${search}`
            )

            let data = resp.data.data ? resp.data.data : null;

            if (isExport) {
                return data
            }

            dispatch({
                type: GET_MEMBER_REFFERAL,
                data
            })
        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const getMemberNotes = ({ page = '', size = '', memberId = '' }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(
                `member-notes?page=${page}&size=${size}&memberId=${memberId}`
            )

            let data = resp.data.data ? resp.data.data : null;

            dispatch({
                type: GET_MEMBER_NOTES,
                data
            })
        } catch (error) {
            console.log(error);
            return error?.response?.data
        }
    }
}

export const getReportMemberGender = (orgId, clubId, type = "member") => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`report/gender?orgId=${orgId}&clubId=${clubId}&type=${type}`)

            let data = resp.data.data ? resp.data.data : null;

            dispatch({
                type: GET_GENDER_MEMBER,
                data,
            })

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const getReportMemberAge = (orgId, clubId, type = "member") => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`report/age?orgId=${orgId}&clubId=${clubId}&type=${type}`)

            let data = resp.data.data ? resp.data.data : null;

            console.log("age member from action", data);


            dispatch({
                type: GET_AGE_MEMBER,
                data,
            })

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const getMember99K = ({ page = "", size = "", orgId = "", clubId = "", isExport = false }) => {
    return async (dispatch) => {
        try {
            const resp = await Api.get(`member-99k?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}`)

            let data = resp.data.data ? resp.data.data : null;

            if (isExport) return data;

            dispatch({
                type: GET_MEMBER_99K,
                data,
            })

        } catch (error) {
            console.log(error)
            return error?.response?.data
        }
    }
}

export const clearListMember = () => {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_LIST_MEMBER,
            data: []
        })
    }
}
