import { GET_LIST_DISC } from "../actions/DiscActions.js";

const initialState = {
    discList: [],
};

const DiscReducer = function(state = initialState, action) {
    switch (action.type) {
        case GET_LIST_DISC: {
            return {
                ...state,
                discList: action.data
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};

export default DiscReducer;
