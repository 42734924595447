import { Api } from '../api'

export const GET_LIST_DISC = "GET_LIST_DISC";

export const getListDisc = ({ page = "", size = "", orgId = "", clubId = "", type = "", value = "" }) => {
    return async (dispatch) => {
        try {
            if (value === null) value = "";
            const resp = await Api.get(
                `master/disc?page=${page}&size=${size}&orgId=${orgId}&clubId=${clubId}&type=${type}&value=${value}`
            )
            let data = resp.data.data ? resp.data.data : []

            dispatch({
                type: GET_LIST_DISC,
                data,
            })
            // return data
        } catch (err) {
            console.log(err)
            throw err?.response?.data
        }
    }
};
